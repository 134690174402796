// Vue setup
import Vue from 'vue';
import { loadProgressBar } from 'axios-progress-bar';
import { SplashScreen } from '@capacitor/splash-screen';
// -- Core and plugins
import { vuetify } from '@/plugins/vuetify';
import { pinia } from '@/plugins/pinia';
import { router as whitelabelRouter } from '@/router/whitelabel';
import { router as brainRouter } from '@/router/brain';
import i18n from '@/plugins/vue-i18n';
import '@/plugins/font-awesome';
import '@/plugins/global-template-helpers';
import '@/plugins/dayjs';
import '@/plugins/sentry';
import '@/plugins/components';
import '@/sass/app.sass';
import '@/filters';
import './polyfills';
import { initDeepLinks } from '@/plugins/deep-links';
import { initMatomo } from '@/plugins/matomo';
import initAuthenticationInterceptor from '@/application/whitelabel/authentication/axios-authentication-response-interceptor';
import initBrainAuthenticationInterceptor from '@/application/brain/authentication/axios-authentication-response-interceptor';
import initAppVersionInterceptor from '@/application/common/app-version-hint/axios-app-version-interceptor';
import initDefaultLanguageInterceptor from '@/application/whitelabel/authentication/axios-default-language-request-interceptor';
import { isMobileApplication } from '@/helpers/detection-helpers';
// -- Components
import WhitelabelApp from '@/application/whitelabel/app/components/app.vue';
import BrainApp from '@/application/brain/app/components/app.vue';

async function initWhitelabelApplication(): Promise<void> {
  new Vue({
    router: whitelabelRouter,
    i18n,
    vuetify,
    pinia,
    render: (h) => h(WhitelabelApp),
    beforeMount: () => {
      initAuthenticationInterceptor();
      initAppVersionInterceptor();
      initDefaultLanguageInterceptor();
    },
    mounted: () => {
      if (isMobileApplication() && SplashScreen) {
        SplashScreen.hide();
      }
      loadProgressBar();
    },
  }).$mount('#app');
}

function initBrainApplication(): void {
  new Vue({
    router: brainRouter,
    i18n,
    vuetify,
    pinia,
    render: (h) => h(BrainApp),
    beforeMount: () => {
      initBrainAuthenticationInterceptor();
      initAppVersionInterceptor();

      document.title = `Alphanect Brain`;
    },
    mounted: () => {
      loadProgressBar();
    },
  }).$mount('#app');
}

const brainHostRegex = /^brain\.alphanect\.|^brain\.(.*)\.develop\.alphanect\.dev/;
// eslint-disable-next-line no-restricted-globals
const doesApplicationHaveToBeBootedAsBrain = brainHostRegex.test(location.host);

// Boot depending on host
if (doesApplicationHaveToBeBootedAsBrain) {
  initBrainApplication();
} else {
  initDeepLinks();
  initMatomo();

  initWhitelabelApplication();
}
