import { render, staticRenderFns } from "./create-investment-profile-dialog.vue?vue&type=template&id=6d10a9c9&"
import script from "./create-investment-profile-dialog.vue?vue&type=script&lang=ts&"
export * from "./create-investment-profile-dialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
installComponents(component, {VCard,VCardTitle,VDialog,VStepper,VStepperContent,VStepperStep})
